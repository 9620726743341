import { defineComponent as _defineComponent } from 'vue'
import { useRoute, useRouter } from "vue-router";
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheClock from "@/components/TheClock.vue";
import TheStep from "@/components/TheStep/TheStep.vue";
import { INVITATION_TIER_ONE, invitationProgress } from "@ems/constants/steps";
import { ref } from "vue";
import { SYSTEM } from "@ems/locales/system";


export default /*@__PURE__*/_defineComponent({
  __name: 'SendInvitation',
  setup(__props, { expose: __expose }) {
  __expose();

const selectedInviteMethod = ref("single");
const router = useRouter();
const route = useRoute();

const handleBack = () => {
  router.push("/brand-owner-information");
};

const handleNext = () => {
  router.push(`${route.path}/${selectedInviteMethod.value}`);
};

const __returned__ = { selectedInviteMethod, router, route, handleBack, handleNext, get useRoute() { return useRoute }, get useRouter() { return useRouter }, TheHeader, TheFooter, TheClock, TheStep, get INVITATION_TIER_ONE() { return INVITATION_TIER_ONE }, get invitationProgress() { return invitationProgress }, ref, get SYSTEM() { return SYSTEM } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})