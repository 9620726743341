import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between font-sans text-sm" }
const _hoisted_2 = { class: "flex flex-col items-center" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = {
  key: 0,
  class: "flex-grow flex justify-center mt-5"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TheStep',
  props: {
  currentStep: {
    type: Number,
    required: true,
  },
  steps: {
    type: Array as () => Array<{
      title: string;
      tooltip: string;
      icon?: string;
    }>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.steps, (step, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "flex"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_tooltip, {
            class: "item",
            effect: "dark",
            content: step.tooltip,
            placement: "top"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["flex items-center justify-center w-10 h-10 mb-2 rounded-full", {
              'bg-green-accent text-green-secondary': index < __props.currentStep,
              'bg-status-draft text-neutral-black': index > __props.currentStep,
              'bg-green-secondary text-white': index === __props.currentStep,
            }])
              }, [
                _createElementVNode("span", _hoisted_3, _toDisplayString(index + 1), 1)
              ], 2)
            ]),
            _: 2
          }, 1032, ["content"]),
          _createElementVNode("span", {
            class: _normalizeClass(["text-center font-bold w-40", {
            'text-green-secondary font-bold': index === __props.currentStep,
            'text-neutral-black font-bold': index !== __props.currentStep,
          }])
          }, _toDisplayString(step.title), 3)
        ]),
        (index < __props.steps.length - 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", {
                class: _normalizeClass(["line", {
            'bg-green-secondary': index < __props.currentStep,
            'bg-status-draft': index >= __props.currentStep,
          }])
              }, null, 2)
            ]))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}
}

})