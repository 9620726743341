export enum ACCOUNT_REGISTRATION_PROGRESS {
  REGISTRATION = 0,
  STATUS = 1,
}

export const accountRegistrationProgress = [
  { title: "Registration", tooltip: "Complete the registration form" },
  { title: "Status", tooltip: "Wait for approval status" },
];

export enum INVITATION_TIER_ONE {
  INVITATION = 0,
  DETAILS = 1,
}

export const invitationProgress = [
  {
    title: "Invitation",
    tooltip: "Choose the invitation method",
  },
  {
    title: "Details",
    tooltip: "Enter the invitation details",
  },
];
