import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col custom-height" }
const _hoisted_2 = { class: "flex flex-col justify-center items-center lg:px-8 py-10" }
const _hoisted_3 = { class: "mt-10 w-full max-w-2xl px-8 pt-6 pb-8 shadow-lg rounded-lg bg-white" }
const _hoisted_4 = { class: "font-bold text-xl text-neutral-dark" }
const _hoisted_5 = { class: "flex items-center gap-4 justify-center rounded-md mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["TheStep"], {
        currentStep: $setup.INVITATION_TIER_ONE.INVITATION,
        steps: $setup.invitationProgress
      }, null, 8, ["currentStep", "steps"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString($setup.SYSTEM.invite_supply_chain_partners), 1),
        _cache[1] || (_cache[1] = _createElementVNode("label", { class: "block mt-8" }, [
          _createTextVNode(" Invitation Method "),
          _createElementVNode("span", { class: "text-red-700" }, "*")
        ], -1)),
        _createVNode(_component_el_select, {
          modelValue: $setup.selectedInviteMethod,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedInviteMethod) = $event)),
          class: "w-full mt-2 custom-placeholder"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_option, {
              label: "Single Invite",
              value: "single"
            }),
            _createVNode(_component_el_option, {
              label: "Bulk Invite",
              value: "bulk"
            })
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          class: "btn bg-white border border-neutral-black hover:bg-gray-100 px-10 py-2",
          onClick: _withModifiers($setup.handleBack, ["prevent"])
        }, " Back "),
        _createElementVNode("button", {
          class: "btn btn--green-primary rounded-md px-10 py-2",
          onClick: _withModifiers($setup.handleNext, ["prevent"])
        }, " Next ")
      ])
    ])
  ]))
}